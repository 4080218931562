<template>
	<span
		v-if="state.isCustomizedIcon"
		:class="['icon', props.filled ? 'filled' : '']"
	>
		<component :is="svgIcon || DefaultIcon" />
	</span>
	<span
		v-else
		:class="['material-symbols-outlined', props?.filled ? 'filled' : '']"
	>
		{{ materialSymbolName }}
	</span>
</template>

<script lang="ts" setup>
import type { ComponentPublicInstance } from "vue";
import DefaultIcon from "./DefaultIcon.vue";

/**
 * @deprecated use Icon from @tengiva/components
 */

// props & state
interface IconProps {
	name: string;
	filled?: boolean;
}
interface IconState {
	isCustomizedIcon: boolean;
	icon: ComponentPublicInstance | null;
}

const props = defineProps<IconProps>();
const state = reactive<IconState>({
	isCustomizedIcon: true,
	icon: null,
});

// computed
const materialSymbolName = computed(() => props.name.replace("md-", ""));

// eslint-disable-next-line vue/return-in-computed-property
const svgIcon = computed(() => {
	try {
		if (props.name.startsWith("md-")) {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			state.isCustomizedIcon = false;
		} else {
			const svgIcon = defineAsyncComponent(() => import(`../../assets/icons/${props.name}.svg`));
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			state.icon = markRaw(svgIcon);
			return markRaw(svgIcon);
		}
	} catch (err) {
		return null;
	}
});
</script>

<style lang="scss" scoped>
.icon {
	@apply inline-flex self-center justify-center items-center h-[24px] w-[24px];
	&.filled {
		:deep(svg) {
			path {
				@apply fill-current;
			}
		}
	}
}

.material-symbols-outlined {
	line-height: inherit;
	font-variation-settings:
		"FILL" 0,
		"wght" 300,
		"GRAD" 0,
		"opsz" 24;
	&.filled {
		font-variation-settings: "FILL" 1;
	}
}
</style>
